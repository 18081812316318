import axios from 'axios'
import { Toast } from 'vant'

axios.defaults.baseURL = 'https://api.smart-business.chamshare.cn/'
axios.defaults.timeout = 30000
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      loadingType: "spinner",
      duration: 0
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    Toast.clear();
    if (response.data.code === 24) {
      window.localStorage.removeItem('user_info')
      // token过期
      Toast('登录过期，请重新登录！')
      window.location.href = window.location.origin + '/login'
    }
    return response;
  },
  (error) => {
    Toast.clear();
    return Promise.reject(error);
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      if (res.data.code !== 0) {
        Toast({
          type: 'fail',
          message: res.data.msg
        })
      }
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(res => {
      if (res.data.code !== 0) {
        Toast({
          type: 'fail',
          message: res.data.msg
        })
      }
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params).then(res => {
      if (res.data.code !== 0) {
        Toast({
          type: 'fail',
          message: res.data.msg
        })
      }
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
